<template>
  <div>
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <div>
        <b-row>
          <b-col md="6">
            <FormulateInput
              id="name"
              v-model="filters.name"
              :label="$t('Nome do Departamento')"
              :placeholder="$t('Nome')"
            />
          </b-col>
        </b-row>
      </div>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>
      <b-table
        ref="department-table"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('DEPARTMENT.NAME'))"
        :fields="fields"
        :items="departments"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(active)="row">
          <e-status-badge :status="row.item.active" />
        </template>

        <template #cell(action)="row">
          <e-grid-actions
            :is-active="row.item.active"
            :show-update="$can('Update', 'ProductDepartment')"
            :show-delete="$can('Delete', 'ProductDepartment')"
            :show-activate="$can('Activate', 'ProductDepartment')"
            :show-deactivate="$can('Deactivate', 'ProductDepartment')"
            @update="showUpdateDepartmentSidebar(row)"
            @delete="deleteDepartment(row)"
            @activate="activateDeactivateDepartment(row)"
            @deactivate="activateDeactivateDepartment(row)"
          />
        </template>
      </b-table>
      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="department-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>
    <product-department-sidebar
      ref="department_sidebar"
      @save="getData"
    />

    <fab
      v-if="$can('Create', 'ProductDepartment')"
      :main-tooltip="$t('Adicionar Departamento')"
      @click="showCreateDepartmentSidebar"
    />
  </div>
</template>

<script>
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  EPageSizeSelector,
  EPaginationSummary,
  EStatusBadge,
  EFilters,
  EGridActions,
} from '@/views/components'
import fab from '@/views/components/FAB.vue'
import { mapActions, mapState } from 'vuex'
import ProductDepartmentSidebar from './ProductDepartmentSidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
    BPagination,
    BTable,
    EPaginationSummary,
    EPageSizeSelector,
    fab,
    EStatusBadge,
    EFilters,
    ProductDepartmentSidebar,
    EGridActions,
  },

  mixins: [],

  data() {
    return {
      fetching: false,
      edit: false,
      saving: false,
    }
  },

  computed: {
    ...mapState('pages/catalog/productDepartments', {
      departments: 'productDepartments',
      paging: 'paging',
      sorting: 'sorting',
      filters: 'filters',
    }),

    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Id'),
          key: 'id',
          class: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Nome'),
          key: 'name',
          trClass: 'text-center',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          label: this.$t('Status'),
          key: 'active',
          class: 'text-center',
          thStyle: { width: '90px' },
          sortable: true,
        },
      ]
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    ...mapActions('pages/catalog/products', { comboFetchDomainData: 'fetchDomainData' }),
    ...mapActions('pages/catalog/productDepartments', [
      'fetchProductDepartments',
      'setCurrentPage',
      'setPageSize',
      'setFilters',
      'resetFilters',
    ]),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchProductDepartments()
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })

      // return this.departments || []
    },

    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.filterData()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    showCreateDepartmentSidebar() {
      this.$refs.department_sidebar.show()
    },

    async showUpdateDepartmentSidebar(row) {
      this.$refs.department_sidebar.show({ id: row.item.id })
    },

    async deleteDepartment(department) {
      const { id } = department.item
      try {
        const confirm = await this.confirm()
        if (confirm) {
          await this.$http.delete(`/api/departments/${id}`)
          this.comboFetchDomainData('departments')
          this.getData()
          this.showSuccess({
            message: this.$t('Departamento removido com sucesso'),
          })
        }
      } catch (error) {
        this.showError({ error })
      }
    },

    async activateDeactivateDepartment(row) {
      try {
        const confirm = await this.confirm()
        if (confirm) {
          const path = row.item.active ? 'deactivate' : 'activate'
          await this.$http({
            url: `/api/departments/${path}/${row.item.id}`,
            method: 'PUT',
          })
          this.comboFetchDomainData('departments')
          this.getData()
          this.showSuccess({
            message: this.$t(`${row.item.active === true ? 'Inativado' : 'Ativado'} com sucesso`),
          })
        }
      } catch (error) {
        this.showError({ error })
      }
    },
  },
}
</script>

<style></style>
